<template>
  <div class="bg-dark">
    <div id="navbar">
      <ul class="tab tab-block">
        <li class="tab-item">
          <a href="#" @click="setPage('TOURS')" v-bind:class="{ active: isActive('TOURS') }">Visningar</a>
        </li>
      </ul>
    </div>
    <div class="container py-2">
      <div class="columns">
        <div class="column col-8 col-lg-12 col-mx-auto">
          <form class="form-horizontal" v-on:submit.prevent="addObject" v-if="isActive('TOURS')">
            <div class="form-group">
              <div class="col-5 col-md-12 col-mx-auto">
                <div class="mb-1" v-for="_object in objects" v-bind:key="_object.id">
                  <button class="btn btn-clear float-right" @click="$emit('removeObject', _object.id)"></button>
                  <span style="cursor:pointer;" @click="selectTour(_object.id)">{{ _object.title }}</span>
                </div>
              </div>
              <div class="col-6 col-md-12">
                <div class="form-group">
                  <div class="col-4 col-sm-12">
                    <label class="form-label" for="input-arrangementdate">Datum</label>
                  </div>
                  <div class="col-8 col-sm-12">
                    <input
                      class="form-input"
                      v-bind:class="{ 'is-error': !arrangementDateValid }"
                      type="date"
                      id="input-arrangementdate"
                      placeholder="YYYY-MM-DD"
                      v-model="arrangementDate"
                      @input="validateArrangementDate" />
                    <p class="form-input-hint" v-if="!arrangementDateValid">Felaktigt datum</p>
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-4 col-sm-12">
                    <label class="form-label" for="input-arrangementstarttime">Starttid</label>
                  </div>
                  <div class="col-8 col-sm-12">
                    <input
                      class="form-input"
                      v-bind:class="{ 'is-error': !arrangementStartTimeValid }"
                      type="text"
                      id="input-arrangementstarttime"
                      placeholder="HH:mm"
                      v-model="arrangementStartTime"
                      @input="validateArrangementStartTime" />
                    <p class="form-input-hint" v-if="!arrangementStartTimeValid">Felaktig tid</p>
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-4 col-sm-12">
                    <label class="form-label" for="input-arrangementstarttime">Sluttid</label>
                  </div>
                  <div class="col-8 col-sm-12">
                    <input
                      class="form-input"
                      v-bind:class="{ 'is-error': !arrangementEndTimeValid }"
                      type="text"
                      id="input-arrangementstarttime"
                      placeholder="HH:mm"
                      v-model="arrangementEndTime"
                      @input="validateArrangementEndTime" />
                    <p class="form-input-hint" v-if="!arrangementEndTimeValid">Felaktig tid</p>
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-4 col-sm-12">
                    <label class="form-label" for="input-arrangementlunchobj">Lunch</label>
                  </div>
                  <div class="col-8 col-sm-12">
                    <label class="form-checkbox">
                      <input
                        id="input-arrangementlunchobj"
                        type="checkbox"
                        v-model="arrangementLunchObj"
                        :disabled="arrangementMeetingObj">
                      <i class="form-icon"></i>
                    </label>
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-4 col-sm-12">
                    <label class="form-label" for="input-arrangementMeetingObj">Samling</label>
                  </div>
                  <div class="col-8 col-sm-12">
                    <label class="form-checkbox">
                      <input
                        id="input-arrangementMeetingObj"
                        type="checkbox"
                        v-model="arrangementMeetingObj"
                        :disabled="arrangementLunchObj">
                      <i class="form-icon"></i>
                    </label>
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-4 col-sm-12">
                    <label class="form-label" for="input-arrangementfacility">Anläggning</label>
                  </div>
                  <div class="col-8 col-sm-12">
                    <input
                      class="form-input"
                      type="text"
                      id="input-arrangementfacility"
                      placeholder="Anläggning X"
                      v-model="arrangementFacility"
                      :disabled="arrangementLunchObj || arrangementMeetingObj" />
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-4 col-sm-12">
                    <label class="form-label" for="input-arrangementdesc">Beskrivning</label>
                  </div>
                  <div class="col-8 col-sm-12">
                    <textarea
                      class="form-input"
                      id="input-arrangementdesc"
                      placeholder="Beskrivning"
                      rows="3"
                      v-model="arrangementDescription">
                    </textarea>
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-4 col-sm-12">
                    <label class="form-label" for="input-arrangementmeetup">Mötesplats</label>
                  </div>
                  <div class="col-8 col-sm-12">
                    <input
                      class="form-input"
                      v-bind:class="{ 'is-error': !arrangementMeetupValid }"
                      type="text"
                      id="input-arrangementmeetup"
                      placeholder="00.000000, 00.000000"
                      v-model="arrangementMeetup"
                      @input="validateArrangementMeetup" />
                    <p class="form-input-hint" v-if="!arrangementMeetupValid">Felaktig koordinat</p>
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-4 col-sm-12">
                    <label class="form-label" for="input-arrangementparking">Parkering</label>
                  </div>
                  <div class="col-8 col-sm-12">
                    <div>
                      <input
                        class="form-input"
                        v-bind:class="{ 'is-error': !arrangementParkingValid }"
                        type="text"
                        id="input-arrangementparking"
                        placeholder="00.000000, 00.000000"
                        v-model="arrangementParking"
                        @input="validateArrangementParking" />
                      <p class="form-input-hint" v-if="!arrangementParkingValid">Felaktig koordinat</p>
                    </div>
                    <p style="font-size: .7rem;">Parkering vid mötesplatsen? Ange samma koordinat för båda</p>
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-4 col-sm-12">
                    <label class="form-label" for="input-arrangementzoom">Zoom</label>
                  </div>
                  <div class="col-8 col-sm-12">
                    <input
                      class="form-input"
                      v-bind:class="{ 'is-error': !arrangementZoomValid }"
                      type="number"
                      id="input-arrangementzoom"
                      v-model="arrangementZoom"
                      min=1
                      max=20
                      @input="validateArrangementZoom" />
                    <p class="form-input-hint" v-if="!arrangementZoomValid">Felaktig zoom</p>
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-8 col-sm-12 col-ml-auto">
                    <button
                      class="btn btn-primary"
                      v-bind:class="{ 'disabled': !arrangementDateValid || !arrangementStartTimeValid || !arrangementEndTimeValid || !arrangementMeetupValid || !arrangementParkingValid || !arrangementZoomValid}">Lägg till</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'Editor',
  data: function () {
    return {
      page: '',
      arrangementDate: '',
      arrangementStartTime: '',
      arrangementEndTime: '',
      arrangementDescription: '',
      arrangementLunchObj: false,
      arrangementMeetingObj: false,
      arrangementFacility: '',
      arrangementMeetup: '',
      arrangementParking: '',
      arrangementZoom: 14,
      arrangementDateValid: false,
      arrangementStartTimeValid: false,
      arrangementEndTimeValid: false,
      arrangementMeetupValid: true,
      arrangementParkingValid: true,
      arrangementZoomValid: true
    }
  },
  props: {
    objects: Array
  },
  methods: {
    isActive: function (isPage) {
      return this.page === isPage
    },
    setPage: function (newPage) {
      if (this.page === newPage) {
        this.page = ''
      } else {
        this.page = newPage
      }
    },
    validateArrangementDate: function (evt) {
      this.arrangementDateValid = moment(this.arrangementDate).isValid()
    },
    validateArrangementStartTime: function (evt) {
      this.arrangementStartTimeValid = moment(this.arrangementStartTime, 'H:mm', true).isValid()
      this.validateArrangementEndTime()
    },
    validateArrangementEndTime: function (evt) {
      this.arrangementEndTimeValid = moment(this.arrangementEndTime, 'H:mm', true).isValid()

      if (this.arrangementEndTimeValid && this.arrangementStartTimeValid) {
        this.arrangementEndTimeValid = moment(this.arrangementEndTime, 'H:mm').isSameOrAfter(moment(this.arrangementStartTime, 'H:mm'))
      }
    },
    validateCoordinate: function (co) {
      let re = /^\d+.\d+,(\s)?\d+.\d+$/
      return re.test(co)
    },
    validateArrangementZoom: function (evt) {
      let parsed = parseInt(this.arrangementZoom, 10)
      this.arrangementZoomValid = !(isNaN(parsed) || parsed < 1 || parsed > 20)
    },
    validateArrangementMeetup: function (evt) {
      this.arrangementMeetupValid = this.arrangementMeetup === undefined || this.arrangementMeetup.length < 1 || this.validateCoordinate(this.arrangementMeetup)
    },
    validateArrangementParking: function (evt) {
      this.arrangementParkingValid = this.arrangementParking === undefined || this.arrangementParking.length < 1 || this.validateCoordinate(this.arrangementParking)
    },
    addObject: function () {
      this.$emit('addObject', {
        date: this.arrangementDate,
        timeStart: this.arrangementStartTime,
        timeEnd: this.arrangementEndTime,
        detailedDescription: this.arrangementDescription,
        lunchObj: this.arrangementLunchObj,
        mettingObj: this.arrangementMeetingObj,
        facility: this.arrangementFacility,
        coordinates_meetup: this.arrangementMeetup,
        coordinates_parking: this.arrangementParking,
        zoom: this.arrangementZoom
      })
    },
    selectTour: function (id) {
      let obj = this.objects.filter(obj => obj.id === id)
      if (obj.length > 0) {
        this.arrangementDate = obj[0].date
        this.arrangementStartTime = obj[0].timeStart
        this.arrangementEndTime = obj[0].timeEnd
        this.arrangementDescription = obj[0].detailedDescription
        this.arrangementLunchObj = obj[0].lunchObj
        this.arrangementMeetingObj = obj[0].mettingObj
        this.arrangementFacility = obj[0].facility
        this.arrangementMeetup = obj[0].coordinates_meetup
        this.arrangementParking = obj[0].coordinates_parking
        this.arrangementZoom = obj[0].zoom
      }
    }
  }
}
</script>

<style lang="scss" scoped>
div#navbar a.active,
div#navbar a:hover {
  border-bottom-color: yellow;
  color: yellow;
}
</style>
