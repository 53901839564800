<template>
  <div class="break-after">
    <div id="doc-firstpage-header">
      <div class="columns">
        <div class="column col-12 flex-centered">
          <img id="doc-firstpage-title" src="../assets/logo.png" />
        </div>
      </div>
      <div class="columns">
        <div class="column col-12 text-center">
          <p id="doc-firstpage-title" v-if="moment().utc(dateStart, 'YYYY-MM-DD').isSame(moment().utc(dateEnd, 'YYYY-MM-DD'))">Arrangemang {{ moment().utc(dateStart, 'YYYY-MM-DD').format("D/M - YYYY") }}</p>
          <p id="doc-firstpage-title" v-else>Arrangemang {{ moment().utc(dateStart, 'YYYY-MM-DD').format("D/M") }} - {{ moment().utc(dateEnd, 'YYYY-MM-DD').format("D/M - YYYY") }}</p>
          <p>{{ description }}</p>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column col-12 text-center">
        <p>Svenska Befästningssällskapet Ideell Förening</p>
        <p>Org.nr: 802516-8538</p>
        <p>Säte: Göteborg</p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'FirstPage',
  props: {
    dateStart: String,
    dateEnd: String,
    description: String
  },
  methods: {
    moment: function () {
      return moment
    }
  }
}
</script>

<style lang="scss" scoped>
div#doc-firstpage-header {
  height: 250mm;
}
img#doc-firstpage-title {
  width: 240px;
  padding-top: 54px;
}
p#doc-firstpage-title {
  padding-top: 24px;
  margin: 0 0 0 0;
  font-size: 24px;
}
</style>
