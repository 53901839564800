<template>
  <div class="break-after">
    <div class="columns forceblock">
      <div class="column col-10 col-mx-auto">
        <p class="doc-fifthpage-title bold">{{ title | toTitle }}</p>
      </div>
    </div>
    <div class="columns forceblock">
      <div class="column col-10 col-mx-auto">
        <img :src="mapUrl" v-if="this.coordinates_meetup !== undefined && this.coordinates_meetup.length > 0" />
      </div>
    </div>
    <div class="columns forceblock">
      <div class="column col-10 col-mx-auto pb-2">
        <p v-if="this.coordinates_meetup !== undefined && this.coordinates_meetup.length > 0">{{ mapDescription }}</p>
        <div class="columns pb-2 pt-2 forceblock">
          <div class="column col-12 detailedDescription">{{ detailedDescription | toDescription }}</div>
        </div>
        <div class="columns">
          <div class="column col-6">
            <div class="columns" v-if="!lunch">
              <div class="column col-6">
                Anläggning
              </div>
              <div class="column col-6">
                {{ facility | toFacility }}
              </div>
            </div>
            <div class="columns">
              <div class="column col-6">
                Datum
              </div>
              <div class="column col-6">
                {{ moment().utc(date, 'YYYY-MM-DD').format("D/M") }}
              </div>
            </div>
            <div class="columns">
              <div class="column col-6">
                Tid
              </div>
              <div class="column col-6">
                {{ moment().utc(timeStart, 'H:mm').format("H:mm") }} - {{ moment().utc(timeEnd, 'H:mm').format("H:mm") }}
              </div>
            </div>
            <div class="columns">
              <div class="column col-6">
                Varaktighet
              </div>
              <div class="column col-6" v-if="objectDurationPlusOneHour">
                {{ moment().utc(objectDuration, 'H:mm').format("H") }} <span v-if="objectDurationEqualOneHour">timme</span><span v-if="!objectDurationEqualOneHour">timmar</span><span v-if="objectDurationPlusOneMinute"> och {{ moment().utc(objectDuration, 'H:mm').format("mm") }} minuter</span>
              </div>
              <div class="column col-6" v-else>
                {{ moment().utc(objectDuration, 'H:mm').format("mm") }} minuter
              </div>
            </div>
          </div>
          <div class="column col-6">
            <div class="columns" v-if="this.coordinates_meetup !== undefined && this.coordinates_meetup.length > 0">
              <div class="column col-6">
                Mötesplats
              </div>
              <div class="column col-6">
                <a :href="coordinateMeetupLink" target="_blank">
                  {{ coordinates_meetup }}
                </a>
              </div>
            </div>
            <div class="columns" v-if="this.coordinates_parking !== undefined && this.coordinates_parking.length > 0">
              <div class="column col-6">
                Parkeringsförslag
              </div>
              <div class="column col-6" v-if="coordinates_parking === coordinates_meetup">
                Vid mötesplatsen
              </div>
              <div class="column col-6" v-else>
                <a :href="coordinateParkingLink" target="_blank">
                  {{ coordinates_parking }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'FifthPage',
  props: {
    title: String,
    facility: String,
    lunch: Boolean,
    date: String,
    timeStart: String,
    timeEnd: String,
    detailedDescription: String,
    coordinates_meetup: String,
    coordinates_parking: String,
    zoom: Number
  },
  data: function () {
    return {
      baseUrl: 'https://maps.googleapis.com/maps/api/staticmap?size=640x300&maptype=roadmap&key=AIzaSyB4PdivLwdAKAv3Png0l1AJVdo6LTUhSuo'
    }
  },
  computed: {
    mapUrl: function () {
      let url = this.baseUrl
      url += '&zoom=' + this.zoom
      if (this.coordinates_meetup !== undefined && this.coordinates_meetup.length > 0) {
        url += '&markers=color:red%7Clabel:M%7C' + this.coordinates_meetup
      }
      if (this.coordinates_parking !== undefined && this.coordinates_parking.length > 0 && this.coordinates_parking !== this.coordinates_meetup) {
        url += '&markers=color:blue%7Clabel:P%7C' + this.coordinates_parking
      }
      return url
    },
    mapDescription: function () {
      let desc = ''
      if (this.coordinates_meetup !== undefined && this.coordinates_meetup.length > 0) {
        desc += 'Möteplats anges på kartan med markör M.'

        if (this.coordinates_parking !== undefined && this.coordinates_parking.length > 0 && this.coordinates_parking !== this.coordinates_meetup) {
          desc += ' '
          desc += 'Parkeringsförslag anges på kartan med markör P.'
        }
      } else if (this.coordinates_parking !== undefined && this.coordinates_parking.length > 0) {
        desc += 'Parkeringsförslag anges på kartan med markör P.'
      }
      return desc
    },
    objectDuration: function () {
      let start = moment.utc(this.timeStart, 'H:mm')
      let end = moment.utc(this.timeEnd, 'H:mm')
      let diff = moment.duration(end.diff(start))
      return moment.utc(+diff).format('H:mm')
    },
    objectDurationPlusOneHour: function () {
      let start = moment.utc(this.timeStart, 'H:mm')
      let end = moment.utc(this.timeEnd, 'H:mm')
      let diff = moment.duration(end.diff(start))
      return diff.hours() > 0
    },
    objectDurationEqualOneHour: function () {
      let start = moment.utc(this.timeStart, 'H:mm')
      let end = moment.utc(this.timeEnd, 'H:mm')
      let diff = moment.duration(end.diff(start))
      return diff.hours() === 1 && diff.minutes() === 0
    },
    objectDurationPlusOneMinute: function () {
      let start = moment.utc(this.timeStart, 'H:mm')
      let end = moment.utc(this.timeEnd, 'H:mm')
      let diff = moment.duration(end.diff(start))
      return diff.minutes() > 0
    },
    coordinateMeetupLink: function () {
      return 'https://www.google.com/maps/search/' + this.coordinates_meetup.replace(/ /g, '')
    },
    coordinateParkingLink: function () {
      return 'https://www.google.com/maps/search/' + this.coordinates_parking.replace(/ /g, '')
    }
  },
  methods: {
    moment: function () {
      return moment
    }
  },
  filters: {
    toTitle: function (value) {
      if (!value) return ''
      return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
    },
    toDescription: function (value) {
      if (!value) return ''
      return value.charAt(0).toUpperCase() + value.slice(1)
    },
    toFacility: function (value) {
      if (!value) return ''
      return value.toLowerCase().replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase() })
    }
  }
}
</script>

<style lang="scss" scoped>
div.detailedDescription {
  white-space: pre-line;
}
#app p.doc-fifthpage-title {
  padding-top: 54px;
  padding-bottom: 24px;
}
</style>
