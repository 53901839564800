<template>
  <div class="break-after">
    <div class="columns">
      <div class="column col-10 col-mx-auto">
        <p id="doc-secondpage-title" class="bold">Allmän information</p>
        <div class="columns">
          <div class="column col-2">
            Datum &amp; tid:
          </div>
          <div class="column col-10" v-if="moment().utc(dateStart, 'YYYY-MM-DD').isSame(moment().utc(dateEnd, 'YYYY-MM-DD'))">
            {{ moment().utc(dateStart, 'YYYY-MM-DD').format("D/M") }}, {{ moment().utc(timeStart, 'H:mm').format("H:mm") }} - {{ moment().utc(timeEnd, 'H:mm').format("H:mm") }}
          </div>
          <div class="column col-10" v-else>
            {{ moment().utc(dateStart, 'YYYY-MM-DD').format("D/M") }} {{ moment().utc(timeStart, 'H:mm').format("H:mm") }} - {{ moment().utc(dateEnd, 'YYYY-MM-DD').format("D/M") }} {{ moment().utc(timeEnd, 'H:mm').format("H:mm") }}
          </div>
        </div>
        <div class="columns">
          <div class="column col-2">
            Plats:
          </div>
          <div class="column col-10">
            Se information om visningarna (i slutet av dokumentet).
          </div>
        </div>
        <div class="columns">
          <div class="column col-2">
            Avgift:
          </div>
          <div class="column col-10">
            {{ price }} kr/person (för hela arrangemanget, betalas genom medlemsportalen)
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column col-10 col-mx-auto">
        <p id="doc-secondpage-title" class="bold">Rekommendationer</p>
        <div class="columns">
          <div class="column col-2">
            Klädsel
          </div>
          <div class="column col-10" v-html="recommendationClothing" />
        </div>
        <div class="columns">
          <div class="column col-2">
            Utrustning
          </div>
          <div class="column col-10" v-html="recommendationGear" />
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column col-10 col-mx-auto">
        <p id="doc-secondpage-title" class="bold">Kontaktinformation</p>
        <div class="columns">
          <div class="column col-12">
            Om ni får förhinder inför arrangemanget så bör ni kontakta oss på följande sätt. Kontakta huvudarrangören i förstahand om flera kontaktpersoner finns angivna. Ring enbart om det är dagen för arrangemanget.
          </div>
        </div>
        <div class="columns pt-2">
          <div class="column col-6 pb-2" v-for="contact in populatedContacts" v-bind:key="contact.name">
            <p v-if="contact.organizer">{{ contact.name | toName }} (huvudarrangör)</p>
            <p v-else>{{ contact.name | toName }}</p>
            <p>{{ contact.email | toEmail }}</p>
            <p>{{ contact.phone | toPhoneNumber }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

import { parsePhoneNumber } from 'awesome-phonenumber'

export default {
  name: 'SecondPage',
  props: {
    dateStart: String,
    dateEnd: String,
    timeStart: String,
    timeEnd: String,
    price: Number,
    recommendationClothing: String,
    recommendationGear: String,
    contacts: Array
  },
  data: function () {
    return {
      populatedContacts: []
    }
  },
  mounted: function () {
    const usernames = this.contacts.map(item => item.organizer)
    const headOrganizerUsername = this.contacts.filter(item => item.headOrganizer).map(item => item.organizer)
    this.axios
      .get('https://dashboard.svbefs.se/auth/admin/realms/FV-LINK/users')
      .then(response => {
        let retData = []
        response.data.filter(item => usernames.includes(item.username)).forEach(item => {
          retData.push({
            username: item.username,
            name: item.firstName + ' ' + item.lastName,
            email: item.email,
            phone: (item.attributes.phone) ? item.attributes.phone[0] : '',
            organizer: false
          })
        })
        let headOrganizer = retData.filter(item => headOrganizerUsername.includes(item.username))
        if (headOrganizer.length > 0) {
          headOrganizer[0].organizer = true
        }
        this.populatedContacts = retData
      })
      .catch(() => {
      })
  },
  methods: {
    moment: function () {
      return moment
    }
  },
  filters: {
    toName: function (value) {
      if (!value) return ''
      return value.toLowerCase().replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase() })
    },
    toEmail: function (value) {
      if (!value) return ''
      return value.toLowerCase()
    },
    toPhoneNumber: function(value) {
      if (!value) return "";
      const pn = parsePhoneNumber( value, { regionCode: 'SE' } );
      return pn.number.national;
    }
  }
}
</script>

<style lang="scss" scoped>
p#doc-secondpage-title {
  padding-top: 54px;
}
</style>
