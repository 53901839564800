<template>
    <div id="app" v-if="!loading">
      <div class="toast toast-error" v-if="error">{{ errorMessage }}</div>
      <div v-else>
        <Editor
          id="editor"
          class="p-sticky"
          :objects="allObjectsSortedFlat"
          @addObject="addObject"
          @removeObject="removeObject" />
        <FirstPage
          :dateStart="startDate"
          :dateEnd="endDate"
          :description="arrangement.title"  />
        <EigthPage />
        <SeventhPage />
        <SecondPage
          :dateStart="startDate"
          :dateEnd="endDate"
          :timeStart="startTime"
          :timeEnd="endTime"
          :price="arrangement.fee"
          :recommendationClothing="arrangement.clothing"
          :recommendationGear="arrangement.gear"
          :contacts.sync="arrangement.organizers" />
        <ThirdPage
          :description="arrangement.description" />
        <FourthPage v-for="(dateObjects) in allObjectsSorted" v-bind:key="dateObjects.date"
          :date="dateObjects.date"
          :objects="dateObjects.objects" />
        <SixthPage />
        <FifthPage v-for="(_object) in allObjectsSortedFlat" v-bind:key="_object.id"
          :title="_object.title"
          :lunch="_object.lunchObj"
          :meeting="_object.mettingObj"
          :facility="_object.facility"
          :date="_object.date"
          :timeStart="_object.timeStart"
          :timeEnd="_object.timeEnd"
          :detailedDescription="_object.detailedDescription"
          :coordinates_meetup="_object.coordinates_meetup"
          :coordinates_parking="_object.coordinates_parking"
          :zoom="_object.zoom" />
      </div>
    </div>
    <div id="app" v-else>
      <div class="loading loading-lg"></div>
    </div>
</template>

<script>
import moment from 'moment'

import Editor from './components/Editor.vue'
import FirstPage from './components/FirstPage.vue'
import SecondPage from './components/SecondPage.vue'
import ThirdPage from './components/ThirdPage.vue'
import FourthPage from './components/FourthPage.vue'
import FifthPage from './components/FifthPage.vue'
import SixthPage from './components/SixthPage.vue'
import SeventhPage from './components/SeventhPage.vue'
import EigthPage from './components/EigthPage.vue'

export default {
  name: 'app',
  components: {
    Editor,
    FirstPage,
    SecondPage,
    ThirdPage,
    FourthPage,
    FifthPage,
    SixthPage,
    SeventhPage,
    EigthPage
  },
  data: function () {
    return {
      cookieName: 'tours',
      loading: true,
      error: false,
      errorMessage: '',
      arrangement: {},
      detailedDescription: 'Beskrivning av arrangemang',
      lastObjectId: 1,
      objects: [
        {
          id: 0,
          date: '2018-11-20',
          timeStart: '9:00',
          timeEnd: '13:00',
          detailedDescription: 'Fiktiv.',
          facility: 'Fiktiv',
          coordinates_meetup: '',
          coordinates_parking: '',
          zoom: 14,
          lunchObj: false,
          mettingObj: false
        }
      ]
    }
  },
  mounted: function () {
    this.loadData()
    if (this.$cookies.isKey(this.cookieName)) {
      this.objects = JSON.parse(this.$cookies.get(this.cookieName))
      console.log(this.objects)
    }
  },
  watch: {
    objects: function () {
      this.$cookies.set(this.cookieName, JSON.stringify(this.objects), '1y')
    }
  },
  computed: {
    isAuthorizedToRead: function () {
      return (
        this.$store.getters.roles.includes('arrangement_read') &&
        (this.$store.getters.roles.includes('applications_read') ||
          this.arrangement.organizers
            .map(o => o.organizer)
            .includes(this.$store.getters.userName))
      )
    },
    startDate: function () {
      if (this.objects.length < 1) return ''
      return this.objects[0].date
    },
    startTime: function () {
      if (this.objects.length < 1) return ''
      return this.objects[0].timeStart
    },
    endDate: function () {
      if (this.objects.length < 1) return ''
      return this.objects[this.objects.length - 1].date
    },
    endTime: function () {
      if (this.objects.length < 1) return ''
      return this.objects[this.objects.length - 1].timeEnd
    },
    allObjectsSorted: function () {
      let ret = []
      let dates = this.objects.map(o => o.date).filter((v, i, a) => a.indexOf(v) === i).sort()
      let i = 1
      dates.forEach(date => {
        let objByTime = this.objects.filter(obj => obj.date === date).sort((a, b) => moment(a.timeStart, 'HH:mm').diff(moment(b.timeStart, 'HH:mm')))
        objByTime.forEach(obj => {
          if (obj.lunchObj) {
            obj.title = 'Lunch'
          } else if (obj.mettingObj) {
            obj.title = 'Samling'
          } else {
            obj.title = 'Visningsobjekt ' + i
            i++
          }
        })
        ret.push({
          date: date,
          objects: objByTime
        })
      })
      return ret
    },
    allObjectsSortedFlat: function () {
      let ret = []
      this.allObjectsSorted.forEach(date => {
        date.objects.forEach(obj => {
          ret.push(obj)
        })
      })
      return ret
    }
  },
  methods: {
    getArrangementId: function () {
      return Number(this.$route.query.arrangement)
    },
    addObject: function (obj) {
      this.lastObjectId++
      obj.id = this.lastObjectId
      this.objects.push(obj)
    },
    removeObject: function (objId) {
      this.objects = this.objects.filter(obj => obj.id !== objId)
    },
    loadData: function () {
      this.loading = true

      let arrangementId = this.getArrangementId()
      if (isNaN(arrangementId)) {
        console.log('Invalid arrangement')
        window.location.replace('https://dashboard.svbefs.se/#/tour/list')
        return
      }

      // eslint-disable-next-line no-new
      new Promise((resolve, reject) => {
        if (!this.$store.getters.roles.includes('arrangement_read')) {
          reject(new Error('no permission to to fetch arrangement'))
        } else {
          this.axios
            .get('internal/arrangement/' + arrangementId)
            .then(response => {
              this.arrangement = response.data
              if (this.isAuthorizedToRead) {
                this.loading = false
                resolve()
              } else {
                this.errorMessage = 'Du har inte behörighet att använda denna tjänst'
                this.error = true
                this.loading = false
                reject(new Error('failed to fetch arrangement'))
              }
            })
            .catch(() => {
              this.errorMessage = 'Något gick fel'
              this.error = true
              this.loading = false
              reject(new Error('failed to fetch arrangement'))
            })
        }
      })
    }
  }
}
</script>

<style lang="scss">
div#editor {
  top: 0;
  left: 0;
  width: 100%;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}
@media print {
  div#editor {display:none;}
  div.forceblock {
    display: block !important;
  }
}
#app {
    font-family: Calibri,Candara,Segoe,Segoe UI,Optima,Arial,sans-serif;
    font-size: 16px;
}
#app p {
    padding: 0 0 0 0;
    margin: 0 0 0 0;
}
.break-after {
  break-after: always;
  page-break-after: always;
}
#app p.bold {
  font-weight: bold;
}
</style>
