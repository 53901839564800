import Keycloak from 'keycloak-js';
import axios from 'axios'
import VueAxios from 'vue-axios'
import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import store from './store'
import '@spectre-org/spectre-css/dist/spectre.css'
import '@spectre-org/spectre-css/dist/spectre-exp.css'
import '@spectre-org/spectre-css/dist/spectre-icons.css'
import VueCookies from 'vue-cookies'

Vue.config.productionTip = false

let initOptions = {
  url: 'https://dashboard.svbefs.se/auth/',
  realm: 'FV-LINK',
  clientId: 'svbefs-pdf-vue',
  onLoad: 'login-required'
}

const keycloak = new Keycloak(initOptions);

keycloak.onTokenExpired = () => {
  //console.log("Token expired");
  keycloak
    .updateToken(30)
    .then(() => {
      axios.defaults.headers.common = {
        Authorization: "Bearer " + keycloak.token
      };
      //console.log("Token refreshed");
    })
    .catch(() => {
      //console.log("Failed to refresh token");
      window.location.reload();
    });
};

try {
    keycloak.init({ onLoad: initOptions.onLoad, checkLoginIframe: false }).then((auth) => {

      if (!auth) {
        window.location.reload();
      }

      store.commit('setUserName', keycloak.idTokenParsed.user_name)
      store.commit(
        'setRoles',
        keycloak.tokenParsed.resource_access['fv-user-api'].roles
      )
      axios.defaults.baseURL = 'https://dashboard.svbefs.se/services/portal-api'
      axios.defaults.headers.common = {
        Authorization: 'Bearer ' + keycloak.token
      }
      Vue.config.productionTip = false
      Vue.use(VueAxios, axios)
      Vue.use(VueRouter)
      Vue.use(VueCookies)

      Vue.$cookies.config('120d')

      var router = new VueRouter({
        mode: 'history',
        routes: []
      })

      new Vue({
        router,
        store,
        render: h => h(App)
      }).$mount('#app')
    });
} catch (error) {

}