<template>
  <div class="break-after">
    <div class="columns forceblock">
      <div class="column col-10 col-mx-auto forceblock">
        <p id="doc-eigthpage-title" class="bold">Ansvar</p>
        <div class="columns forceblock">
          <div id="urgingDescription" class="block column col-12">
            <p>
              Besök i anläggningar kan vara behäftade med vissa risker, t.ex. fall i öppna schakt eller dålig luft som kan medföra hälsoproblem. När du deltar på våra arrangemang gäller därför följande.
            </p>
            <p>
              Alla besök är frivilliga och du är fri att avbryta besöket när som helst, under förutsättning att arrangörsansvarig(a) meddelas.
            </p>
            <p>
              Alla deltagare ska vara aktsamma och iaktta försiktighet under hela arrangemanget samt följa våra och anläggningsinnehavarens/markägarens anvisningar. Du ska göra ditt yttersta för att förhindra att skador uppstår på en anläggning, du får inte under några omständigheter ta med dig sådant som hör till en anläggning. Du ansvarar själv för skador på kläder eller på din egen person som kan uppstå under arrangemanget. Arrangemanget sker på egen risk för alla deltagare. Alla deltagare ansvarar själva för att medtaga lämplig utrustning och klädsel.
            </p>
            <p>
              Skador som orsakas genom en deltagares oaktsamhet skall ersättas enligt reglerna i skadeståndslagen (1972:207). Detta kan gälla skador på anläggning eller på utrustning som tillhör en annan person. Även en person som ej har fyllt 18 år kan bli skadeståndsskyldig (2 kap 4 § skadeståndslagen). En vuxen som medföljer en underårig person på ett arrangemang skall ersätta hela skadan, i den mån den inte ersätts av den underårige själv.
            </p>
            <p>
              Vi gör vårt bästa för att informera om riskerna inför arrangemanget och samarbetar med anläggningsinnehavaren/markägaren för att minimera dessa i den mån det är möjligt.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SeventhPage'
}
</script>

<style lang="scss" scoped>
div#urgingDescription {
  white-space: pre-line;
}
p#doc-eigthpage-title {
  padding-top: 54px;
}
</style>
<style lang="scss">
div#urgingDescription {
  p {
    margin-bottom: 0.5em;
  }
}
</style>
