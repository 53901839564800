<template>
  <div class="break-after">
    <div class="columns forceblock">
      <div class="column col-10 col-mx-auto">
        <p id="doc-fourthpage-title" class="bold">Tidslinje {{ moment().utc(date, 'YYYY-MM-DD').format("D/M") }}</p>
        <div class="columns" v-for="(_object, index) in timelineObjects" v-bind:key="_object.id">
          <div class="column col-2 timeline-container">
            <div class="timeline-tag">
              <p class="timeline-tag-text">{{ moment().utc(_object.timeStart, 'H:mm').format("H:mm") }}</p>
            </div>
            <div class="flex-centered timeline-line-container" v-if="index != timelineObjects.length - 1">
              <div class="timeline-line-line"></div>
            </div>
          </div>
          <div class="column col-10">
            <p class="timeline-title">
              {{ _object.title | toTitle }}
            </p>
            <p class="timeline-content">
              {{ _object.description | toDescription }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'FourthPage',
  props: {
    date: String,
    objects: Array
  },
  computed: {
    timelineObjects: function () {
      let timelineObjects = []
      if (this.objects.length < 1) {
        return timelineObjects
      }
      this.objects.forEach(function (_object, idx, array) {
        let desc = ''
        if (_object.lunchObj) {
          desc = 'Tid reserverad för lunch'
        } else if (_object.mettingObj) {
          desc = 'Tid reserverad för samling'
        } else {
          desc = 'Visning av ' + _object.facility
        }
        timelineObjects.push({
          timeStart: _object.timeStart,
          title: _object.title,
          description: desc
        })
        if (idx !== array.length - 1 && _object.timeEnd !== array[idx + 1].timeStart) {
          timelineObjects.push({
            timeStart: _object.timeEnd,
            title: 'Transport',
            description: 'Transport till nästa visningsobjekt.'
          })
        }
      })
      timelineObjects.push({
        timeStart: this.objects[this.objects.length - 1].timeEnd,
        title: 'Avslut',
        description: 'Slut för idag.'
      })
      return timelineObjects
    }
  },
  methods: {
    moment: function () {
      return moment
    }
  },
  filters: {
    toTitle: function (value) {
      if (!value) return ''
      return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
    },
    toDescription: function (value) {
      if (!value) return ''
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  }
}
</script>

<style lang="scss" scoped>
p#doc-fourthpage-title {
  padding-top: 54px;
  padding-bottom: 24px;
}
#app p.timeline-title {
  font-weight: bold;
  padding: 5px 10px 3px 10px;
}
#app p.timeline-content {
  padding: 5px 10px 30px 10px;
}
div.timeline-container {
  display: flex;
  flex-flow: column;
}
div.timeline-tag {
  border: 1px solid black;
  border-radius: 6px;
}
#app p.timeline-tag-text {
  padding: 5px 10px 3px 10px;
  text-align: center;
}
div.timeline-line-container {
  flex: 1;

  div.timeline-line-line {
    border-left: 1px solid black;
    height: 100%;
  }
}
</style>
