<template>
  <div class="break-after">
    <div class="columns forceblock">
      <div class="column col-10 col-mx-auto forceblock">
        <p id="doc-sixtpage-title" class="bold">Koordinatangivelser</p>
        <div class="columns forceblock">
          <div id="coordinateDescription" class="block column col-12">
            <p>
              Föreningen använder alltid kartor som tillhandahålls av Google. För varje kartbild som används av föreningen betalar föreningen en smärre summa som bl.a. är inräknad i avgiften för detta arrangemang. Därtill använder föreningen alltid decimalkoordinater som är väl integrerat med Googles karttjänster. Eftersom föreningen använder dessa tjänster och de är gratis att använda för dig som deltagare på detta arrangemang rekommenderar vi att du använder <a href="https://www.google.com/maps" target="_blank">Google Maps</a> för att söka upp koordinatangivelser i detta dokument. Koordinatangivelser i detta dokument går att kopia och klistra in direkt i <a href="https://www.google.com/maps" target="_blank">Google Maps</a> utan något behov av konvertering.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SixthPage'
}
</script>

<style lang="scss" scoped>
div#coordinateDescription {
  white-space: pre-line;
}
p#doc-sixtpage-title {
  padding-top: 54px;
}
</style>
<style lang="scss">
div#coordinateDescription {
  p {
    margin-bottom: 0.5em;
  }
}
</style>
