<template>
  <div class="break-after">
    <div class="columns forceblock">
      <div class="column col-10 col-mx-auto forceblock">
        <p id="doc-seventhpage-title" class="bold">Spridning av information</p>
        <div class="columns forceblock">
          <div id="publishDescription" class="block column col-12">
            <p>
              Information som tillhandahålls via Fortifikation-Väst eller Svenska Befästningssällskapet är enbart för individuellt bruk och får inte spridas vidare. Sådan information kan exempelvis handla om anläggningar samt tidpunkt, datum och plats för arrangemang.
            </p>
            <p>
              Publicering av bilder och information från våra arrangemang är generellt tillåtet. Det kan förekomma fall då publicering inte är tillåtet eller lämpligt, därav bör föreningens ledning eller arrangörsansvarig(a) tillfrågas innan publicering. Vi kräver att Svenska Befästningssällskapet och/eller Fortifikation-Väst omnämns som arrangör i samband med publicering.
            </p>
            <p>
              Publicering av geografisk information för anläggningar ingående i våra arrangemang är generellt förbjudet. Undantag finns från denna regel, t.ex. då anläggningen är ett känt museum som t.ex Oscar II fort i Göteborg. Det är föreningens ledning eller arrangörsansvarig(a) som beslutar om undantag och bör således tillfrågas innan publicering.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SeventhPage'
}
</script>

<style lang="scss" scoped>
div#publishDescription {
  white-space: pre-line;
}
p#doc-seventhpage-title {
  padding-top: 54px;
}
</style>
<style lang="scss">
div#publishDescription {
  p {
    margin-bottom: 0.5em;
  }
}
</style>
