import { render, staticRenderFns } from "./SixthPage.vue?vue&type=template&id=62141bf3&scoped=true"
import script from "./SixthPage.vue?vue&type=script&lang=js"
export * from "./SixthPage.vue?vue&type=script&lang=js"
import style0 from "./SixthPage.vue?vue&type=style&index=0&id=62141bf3&prod&lang=scss&scoped=true"
import style1 from "./SixthPage.vue?vue&type=style&index=1&id=62141bf3&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62141bf3",
  null
  
)

export default component.exports