<template>
  <div class="break-after">
    <div class="columns forceblock">
      <div class="column col-10 col-mx-auto forceblock">
        <p id="doc-thirdpage-title" class="bold">Program</p>
        <div class="columns forceblock">
          <div id="detailedDescription" class="block column col-12" v-html="description"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ThirdPage',
  props: {
    description: String
  }
}
</script>

<style lang="scss" scoped>
div#detailedDescription {
  white-space: pre-line;
}
p#doc-thirdpage-title {
  padding-top: 54px;
}
</style>
<style lang="scss">
div#detailedDescription {
  p {
    margin-bottom: 0.5em;
  }
}
</style>
